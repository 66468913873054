import React, { SVGProps } from 'react';

const InboundSidepop = (props: SVGProps<SVGSVGElement>) => (
  <svg width="60" height="40" viewBox="0 0 60 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 4C1 2.34315 2.34315 1 4 1H56C57.6569 1 59 2.34315 59 4V36C59 37.6569 57.6569 39 56 39H4C2.34315 39 1 37.6569 1 36V4Z"
      stroke="#828282"
      strokeWidth="2"
    />
    <path
      d="M6.61024 36.1916C8.01436 36.1916 9.15262 35.1256 9.15262 33.8106C9.15262 32.4957 8.01436 31.4297 6.61024 31.4297C5.20613 31.4297 4.06787 32.4957 4.06787 33.8106C4.06787 35.1256 5.20613 36.1916 6.61024 36.1916Z"
      fill="#AA00FF"
    />
    <path
      d="M4.06787 16.2383C4.06787 15.686 4.51559 15.2383 5.06787 15.2383H17.3052C17.8574 15.2383 18.3052 15.686 18.3052 16.2383V29.4764C18.3052 30.0287 17.8574 30.4764 17.3052 30.4764H5.06787C4.51559 30.4764 4.06787 30.0287 4.06787 29.4764V16.2383Z"
      fill="#AA00FF"
    />
    <g filter="url(#filter0_i)">
      <path
        d="M2.03394 10.4766H57.9661V3.90513C57.9661 2.80056 57.0707 1.90513 55.9661 1.90513H4.03393C2.92936 1.90513 2.03394 2.80057 2.03394 3.90513V10.4766Z"
        fill="#F4F5F7"
      />
    </g>
    <path
      d="M5.59329 6.7048C6.43576 6.7048 7.11872 6.06521 7.11872 5.27623C7.11872 4.48725 6.43576 3.84766 5.59329 3.84766C4.75083 3.84766 4.06787 4.48725 4.06787 5.27623C4.06787 6.06521 4.75083 6.7048 5.59329 6.7048Z"
      fill="#828282"
    />
    <path
      d="M9.5513 6.66574C10.3938 6.66574 11.0767 6.02614 11.0767 5.23716C11.0767 4.44819 10.3938 3.80859 9.5513 3.80859C8.70883 3.80859 8.02588 4.44819 8.02588 5.23716C8.02588 6.02614 8.70883 6.66574 9.5513 6.66574Z"
      fill="#828282"
    />
    <path
      d="M13.5291 6.68527C14.3716 6.68527 15.0545 6.04567 15.0545 5.2567C15.0545 4.46772 14.3716 3.82812 13.5291 3.82812C12.6866 3.82812 12.0037 4.46772 12.0037 5.2567C12.0037 6.04567 12.6866 6.68527 13.5291 6.68527Z"
      fill="#828282"
    />
    <defs>
      <filter
        id="filter0_i"
        x="2.03394"
        y="1.90625"
        width="55.9322"
        height="8.57143"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.509804 0 0 0 0 0.509804 0 0 0 0 0.509804 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
);

export default InboundSidepop;
