import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import cx from 'classnames';
import React from 'react';
import { Icons, IconType } from '@mutiny-pkg/dumpster-ui/icons';
import { Badge } from '../Badge/Badge';
import { Tooltip } from '../Tooltip';
import * as style from './Button.css';
import { NoticeDot } from './NoticeDot';
import { Spinner } from '../Loaders/Spinner';

export enum ButtonVariantEnum {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  destructive = 'destructive',
  recommendation = 'recommendation',
  text = 'text',
  close = 'close',
  modalClose = 'modalClose',
  aiPrimary = 'aiPrimary',
  aiSecondary = 'aiSecondary',
  filter = 'filter',
  transparent = 'transparent',
}

export enum ButtonSizeEnum {
  small = 'small',
  large = 'large',
  minimal = 'minimal',
}

export type ButtonVariantTypes = NonNullable<style.ButtonVariants>;

type BaseButtonProps = style.ButtonVariants & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = BaseButtonProps & {
  iconLeft?: IconType;
  iconRight?: IconType;
  loading?: boolean;
  iconSize?: style.IconSizeVariant;
};

export type IconButtonProps = Omit<BaseButtonProps, 'iconOnly'> & {
  icon: IconType;
  iconSize?: style.IconSizeVariant;
  label: string;
  children?: never;
};

export type BadgeButtonProps = Omit<BaseButtonProps, 'variant' | 'iconOnly'> & {
  variant: 'secondary' | 'tertiary';
  badgeText: string | number;
};

export type NoticeButtonProps = ButtonProps & {
  notice: string | null;
};

const ButtonInnerContentWrapper = ({ loading, children }: Partial<ButtonProps>) =>
  loading ? <div className={style.loadingButtonContent}>{children}</div> : <>{children}</>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      size,
      fullWidth,
      display,
      iconOnly = false,
      disabled = false,
      children,
      iconLeft: IconLeft,
      iconRight: IconRight,
      loading = false,
      className,
      type,
      iconSize = 'default',
      ...props
    }: ButtonProps,
    forwardedRef,
  ): JSX.Element => {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type ?? 'button'}
        className={cx(
          style.button({
            iconOnly,
            variant,
            size,
            fullWidth,
            display,
            iconSize,
          }),
          className,
        )}
        disabled={disabled || loading}
        ref={forwardedRef}
        {...props}
      >
        <ButtonInnerContentWrapper loading={loading}>
          {variant === ButtonVariantEnum.recommendation && (
            <Icons.Sparkles2 className={style.buttonIcon({ size: 'default' })} />
          )}
          {IconLeft && <IconLeft className={style.buttonIcon({ size: 'default' })} />}
          {children}
          {IconRight && <IconRight className={style.buttonIcon({ size: 'default' })} />}
        </ButtonInnerContentWrapper>
        {loading && <Spinner className={style.loadingIconPlacement} />}
      </button>
    );
  },
);

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon: Icon, label, iconSize = 'default', ...props }: IconButtonProps, forwardedRef): JSX.Element => {
    return (
      <Button {...props} ref={forwardedRef} iconOnly iconSize={iconSize}>
        <AccessibleIcon.Root label={label}>
          <Icon className={style.iconSizes[iconSize]} />
        </AccessibleIcon.Root>
      </Button>
    );
  },
);

export const BadgeButton = React.forwardRef<HTMLButtonElement, BadgeButtonProps>(
  ({ badgeText, children, ...props }: BadgeButtonProps, forwardedRef): JSX.Element => (
    <Button {...props} ref={forwardedRef}>
      <Badge variant="badgedButton">{badgeText}</Badge>
      {children}
    </Button>
  ),
);

export const NoticeButton = React.forwardRef<HTMLButtonElement, NoticeButtonProps>(
  ({ notice, children, ...props }: NoticeButtonProps, forwardedRef): JSX.Element => {
    if (notice == null) {
      return (
        <Button {...props} ref={forwardedRef}>
          {children}
        </Button>
      );
    }

    const { variant } = props;

    return (
      <Tooltip asChild content={notice}>
        <Button {...props} ref={forwardedRef}>
          {children}
          <NoticeDot border={!variant || variant === 'primary' || variant === 'recommendation' ? 'light' : 'dark'} />
        </Button>
      </Tooltip>
    );
  },
);
