import React from 'react';

import * as Popover from '../Popover/Popover';
import { Box } from '../Box/Box';
import * as Flex from '../Flex/Flex';
import * as styles from './ColorPicker.css';
import Sketch from '@uiw/react-color-sketch';
import { debounce } from 'lodash';

type ColorPickerProps = {
  value: string;
  onChange: (color: string) => void;
  colorPresets?: string[];
  size?: 'small' | 'default';
};

export const ColorPicker = (props: ColorPickerProps) => {
  const { value, colorPresets = [], size = 'default' } = props;
  const additionalColors: string[] = ['#ffffff', '#000000'];
  const uniqueColorPresets = Array.from(new Set([...colorPresets, ...additionalColors])) as string[];

  const debouncedOnChange = React.useCallback(
    debounce((color: string) => props.onChange(color), 300),
    [props.onChange],
  );
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Box className={styles.ColorPickerContainer({ size })}>
          <Box border="default" className={styles.ColorSwatch} style={{ background: value }} />
        </Box>
      </Popover.Trigger>
      <Popover.Content hideCloseButton>
        <Flex.Container padding="none">
          <Sketch
            color={value}
            onChange={(color) => {
              debouncedOnChange(color.hexa);
            }}
            presetColors={uniqueColorPresets}
          />
        </Flex.Container>
      </Popover.Content>
    </Popover.Root>
  );
};
