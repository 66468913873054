import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { ReactEditor } from 'slate-react';
import cx from 'classnames';
import { DescendantType, VariablesTextInput, useVariablesTextInput } from './VariablesTextInput';
import { Button, IconButton } from '../Button';
import { Container, Item } from '../Flex/Flex';
import { Typography } from '../Typography';
import { vars } from '../../theme/theme.css';
import { Variable, VariableDropdown } from '../Dropdown/VariableDropdown';
import { userPromptCardStyles, userPromptContainerStyles } from './UserPromptInput.css';

export enum UserPromptInputVariant {
  SINGLE = 'single',
  ITERABLE = 'iterable',
}

export type PromptHeaderProps = {
  totalIterations: number;
  currentIterationIndex: number;
  currentIterationFeedbackString?: string;
  currentIterationFeedbackLength?: number;
  onIterationForward?: () => void;
  onIterationBackward?: () => void;
  onRestoreFeedback: () => void;
};

export type UserPromptProps = {
  editor: ReactEditor;
  variables: Variable[];
  initialValue?: DescendantType[];
  animateIn?: boolean;
  currentIterationFeedback?: any[];
  currentIterationFeedbackString?: string;
  totalIterations?: number;
  currentIterationIndex?: number;
  onSubmit: (feedbackRestored: boolean) => void;
  onIterationForward?: () => void;
  onIterationBackward?: () => void;
  onRetry?: () => void;
  children?: React.ReactNode;
  variant?: UserPromptInputVariant;
};

const PromptHeader = ({
  currentIterationIndex,
  totalIterations,
  currentIterationFeedbackString = '',
  currentIterationFeedbackLength = 0,
  onIterationForward,
  onIterationBackward,
  onRestoreFeedback,
}: PromptHeaderProps) => {
  const iterationHeaderSuffix =
    currentIterationFeedbackLength < 2 ? '' : ` & ${currentIterationFeedbackLength - 1} other`;
  return (
    <Container
      style={{ width: '100%' }}
      justifyContent="space-between"
      flexDirection="row"
      alignItems="center"
      gap="2u"
    >
      {totalIterations > 1 && currentIterationIndex > 0 ? (
        <Item style={{ flexGrow: 2 }}>
          <Container justifyContent="flex-start" flexDirection="row" alignItems="center" gap="2u">
            <Icons.ChevronRight />
            <Typography font="body1" fontWeight="medium" wrapText>
              {currentIterationFeedbackString.slice(0, 20).replace(/\n/g, '') +
                (currentIterationFeedbackString.length > 15 ? '...' : '')}
            </Typography>
            <Typography font="body1" fontWeight="medium">
              {iterationHeaderSuffix}
            </Typography>
            <IconButton icon={Icons.Edit} variant="text" label="Restore feedback" onClick={onRestoreFeedback} />
          </Container>
        </Item>
      ) : (
        <Item style={{ flexGrow: 2 }}>
          <Container justifyContent="flex-start" flexDirection="row" alignItems="center" gap="2u">
            <Icons.AiBolt style={{ color: vars.color.electricPurple }} />
            <Typography font="body1" fontWeight="semibold">
              What else would you change?
            </Typography>
          </Container>
        </Item>
      )}
      {totalIterations > 1 && (
        <Item style={{ flexGrow: 1 }}>
          <Container justifyContent="flex-end" flexDirection="row" alignItems="center" gap="2u">
            <IconButton
              icon={Icons.ChevronLeft}
              variant="text"
              label="Previous iteration"
              disabled={currentIterationIndex === 0}
              onClick={onIterationBackward}
            />
            <Typography font="caption1" fontWeight="regular">
              {currentIterationIndex + 1} / {totalIterations}
            </Typography>
            <IconButton
              icon={Icons.ChevronRight}
              variant="text"
              label="Next iteration"
              disabled={currentIterationIndex === totalIterations - 1}
              onClick={onIterationForward}
            />
          </Container>
        </Item>
      )}
    </Container>
  );
};

export const UserPromptInput = ({
  editor,
  variables,
  onSubmit,
  onRetry,
  totalIterations = 0,
  currentIterationIndex = 0,
  currentIterationFeedback,
  currentIterationFeedbackString,
  onIterationForward,
  onIterationBackward,
  initialValue,
  animateIn = true,
  variant = UserPromptInputVariant.ITERABLE,
  children,
}: UserPromptProps) => {
  const { insertVariable, getPlainText } = useVariablesTextInput();
  const [feedbackRestored, setFeedbackRestored] = React.useState(false);
  const [submitEnabled, setSubmitEnabled] = React.useState(false);

  const onRestoreFeedback = () => {
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
    editor.children = currentIterationFeedback?.flat() ?? [];
  };
  return (
    <Container flexWrap="wrap" className={cx(userPromptContainerStyles({ animateIn, variant }))}>
      {variant === UserPromptInputVariant.ITERABLE && (
        <PromptHeader
          totalIterations={totalIterations}
          currentIterationFeedbackString={currentIterationFeedbackString}
          currentIterationFeedbackLength={currentIterationFeedback?.length}
          currentIterationIndex={currentIterationIndex}
          onIterationForward={onIterationForward}
          onIterationBackward={onIterationBackward}
          onRestoreFeedback={() => {
            onRestoreFeedback();
            setFeedbackRestored(true);
          }}
        />
      )}
      <Container
        className={cx(userPromptCardStyles({ submitEnabled }))}
        gap="2u"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Item style={{ flexGrow: 1, maxWidth: '100%', minWidth: 0 }}>
          <VariablesTextInput
            placeholder="Describe how you want to further personalize this copy"
            editor={editor}
            variables={variables}
            initialValue={initialValue ?? [{ type: 'paragraph', children: [{ text: '' }] }]}
            handleSubmit={() => onSubmit(feedbackRestored)}
            onValueChange={(value) => {
              if (getPlainText(value)) {
                setSubmitEnabled(true);
              } else {
                setSubmitEnabled(false);
              }
            }}
          />
        </Item>
        {variant === UserPromptInputVariant.ITERABLE && (
          <Item>
            <IconButton
              label="Submit"
              variant="primary"
              size="large"
              icon={Icons.ArrowUp}
              onClick={() => {
                onSubmit(feedbackRestored);
                setFeedbackRestored(false);
              }}
              disabled={!submitEnabled}
            />
          </Item>
        )}
      </Container>
      <Container fullWidth gap="2u" flexDirection="row" justifyContent="flex-start" alignItems="center">
        {variables.length > 0 && (
          <VariableDropdown
            variables={variables}
            onVariableSelected={(variable) => {
              insertVariable(editor, variables.find(({ value }) => value === variable)?.name ?? variable, variable);
            }}
          >
            <Button iconLeft={Icons.Plus} iconRight={Icons.ChevronDown} variant="text" size="small">
              Insert a variable
            </Button>
          </VariableDropdown>
        )}

        {children}

        {onRetry && (
          <Button iconLeft={Icons.Refresh} variant="text" size="small" onClick={onRetry}>
            Retry
          </Button>
        )}
      </Container>
    </Container>
  );
};
